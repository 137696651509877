
import { defineComponent, onMounted, ref, reactive, toRefs } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { wordingCompare } from '@/API/program'
export default defineComponent({
    name: 'Wording Compare',
    setup() {
        const store = useStore()
        const router = useRouter()
        const data = reactive({
            compareData: '',
            updateData: ''
        })
        const init = () => {
            const params = {
                'programId': store.state.pgmData.programId,
                // 'programId': '0b53c2c3-d445-421d-b237-7d915095d385',
                'type': 'External',
                'lang': 'en'
            }
            if (sessionStorage.getItem('params') && sessionStorage.getItem('params') !== null) {
                Object.assign(params, JSON.parse(sessionStorage.getItem('params') as string))
            }
            wordingCompare({params: params}).then(res => {
                data.compareData = res.original
                data.updateData = res.editable
            })
        }
        const isFixed = ref(false)
            const screenHeight = ref(window.screen.width)
            const mainWidth = ref<number>(0)
            const leftWidth = ref<number>(0)
            store.commit('updatedHeight', screenHeight.value)
        onMounted(() => {
            init()
            window.addEventListener('scroll', () => {
                isFixed.value = document.documentElement.scrollTop > 100
            })
            try {
                    const resizeHandler = (): void => {
                        const clientWidth: number = document.body.clientWidth;
                        const sideNavWidth: number = (document.querySelector('.side-nav-left') as HTMLDivElement).offsetWidth;
                        const W = clientWidth - sideNavWidth - 50;
                        
                        leftWidth.value = (document.querySelector('.preview-box-com-area') as HTMLDivElement).offsetWidth * 2
                        mainWidth.value = W;
                    }
                    window.addEventListener('resize', resizeHandler);
                    resizeHandler();
                } catch (e) {
                    console.log(e);
                }
        })
        return {
            isFixed,
            ...toRefs(data),
            mainWidth,
            leftWidth
        }
    }
})

